import type { FC, PropsWithChildren } from 'react';
import { usePageTitle } from './use-page-title.js';

interface Props {
  title: string | undefined;
}

export const PageTitle: FC<PropsWithChildren<Props>> = ({
  children,
  title,
}) => {
  usePageTitle(title);
  return <>{children}</>;
};
